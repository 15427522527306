import "./bootstrap";
import "./fontawesome";

import ExternalLinks  from "./externallinks";
(new ExternalLinks('body')).addLinkTarget();


let popups = document.querySelectorAll('[data-mfp-type]');
if(popups.length) {

    import(/* webpackChunkName: "magnific" */ './magnificpopup')
        .then((Magnific) => {
            let magnific =  new Magnific.default(popups);
            magnific.initialize()
        })

}

let forms = document.getElementsByClassName('needs-validation');
if(forms.length) {
    import(/* webpackChunkName: "forms" */ './form')
        .then((Forms) => {
            let form =  new Forms.default(forms);
            form.submitButton().validate();
        })
}


let shares = document.querySelectorAll('[data-share-default]');
if(shares.length) {
    import(/* webpackChunkName: "share" */ './share')
        .then((ShareHandler) => {
            for (let i = 0; i < shares.length; i++) {
                const share = shares[i];
                (new ShareHandler.default(share)).initialize();
            }
        })
}

import(/* webpackChunkName: "lazysizes" */ './lazysizes')
    .then(() => {
        // wait for lazysizes before firing glider
        console.log('loaded lazysizes!');
})

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}

(function mainScript() {
    "use strict";
    const offcanvasToggle = document.querySelector(
        '[data-bs-toggle="offcanvas"]',
    );
    const offcanvasCollapse = document.querySelector(".offcanvas-collapse");
    offcanvasToggle.addEventListener("click", function () {
        offcanvasCollapse.classList.toggle("open");
    });
})();
    
var menuBtn = $('.menu-btn');

menuBtn.on('click', function() {
    $(this).toggleClass('active');
    $(this).toggleClass('not-active');
});